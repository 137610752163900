import React, {useEffect, useState} from "react";
import {ILandingBlock} from "../types/ILandingBlock";
import {ILanding} from "../types/ILanding";
import {toNumber} from "lodash";
import SessionModel from "../stores/SessionModel";
import * as CommonActions from "./common/CommonActions";
import {EditTextBlock} from "./common/EditTextBlock";
import {sanitize} from "./common/CommonActions";
import {getBlockDefaults} from "./helper/Util";

export const Wtfa:React.FC<{
    landing: ILanding | undefined,
    setLanding: (landing: ILanding) => void}> = ({landing, setLanding}) => {

    const [block, setBlock] = useState<ILandingBlock | undefined>();
    const [showEdit, setShowEdit] = useState(false);

    useEffect(() => {
        setBlock(landing?.blocks?.filter(block => { return block.type === "WTFA" })[0]);
    }, [landing]);

    if (block) {
        return (
            <>
                <section className={block.isActive ? "newdes-section-standart" : "newdes-section-standart newdes-hidden-block"} id="challenge">
                    {SessionModel.getCurrentUserAuthData() && toNumber(SessionModel.getCurrentUserAuthData().id) === landing?.userId ?
                        <div className="newdes-edit-line">
                            <div className="newdes-click-edit" onClick={() => setShowEdit(true)}>
                                <div className="newdes-edit-icon"></div>
                                <p className="newdes-edit-text" onClick={() => setShowEdit(true)}>Редактировать блок</p>
                            </div>
                            {block.isActive ?
                                <p className="newdes-hidden-element" onClick={() => CommonActions.switchVisibility(block, landing, setLanding)}>Скрыть блок</p>
                                :
                                <p className="newdes-visible-element" onClick={() => CommonActions.switchVisibility(block, landing, setLanding)}>Показать</p>
                            }
                        </div>
                        :
                        <></>
                    }
                    <h2 className="newdes-heading-block">
                        {block.title || "Вызов"}
                    </h2>
                    <p className="newdes-text-description" dangerouslySetInnerHTML={{__html: block.description && block.description.replace(/<\/?[^>]+(>|$)/g, "") ? sanitize(block.description || getBlockDefaults(block).description || "") : getBlockDefaults(block).description}}/>
                    <ul className="newdes-tag-list">
                        {landing?.challenge?.skills && landing.challenge.skills.split(",").map((skill: string, index) =>
                            <li className="newdes-tag-item" key={index}>
                                {skill}
                            </li>
                        )}
                    </ul>
                    <svg xmlns="http://www.w3.org/2000/svg" width="187" height="356" viewBox="0 0 187 356"
                         fill="none" className="newdes-position-svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M184.793 22.7528C189.671 12.1524 181.927 0.0636139 170.258 0.0636139H89.4476C83.2006 0.0636139 77.5248 3.69947 74.9131 9.37439L7.91021 154.961C3.03162 165.561 10.7756 177.65 22.4448 177.65H54.2213C65.8905 177.65 73.6345 189.739 68.7559 200.339L1.49503 346.486C-0.386389 350.574 2.60008 355.236 7.10027 355.236V355.236C8.6893 355.236 10.2171 354.623 11.3654 353.525L166.42 205.212C176.845 195.24 169.787 177.65 155.36 177.65H138.481C126.812 177.65 119.068 165.561 123.947 154.961L184.793 22.7528Z"
                              fill="#2889CE" className="newdes-style-svg"/>
                    </svg>
                </section>

                {showEdit ?
                    <EditTextBlock visible={showEdit} setVisible={setShowEdit} block={block} setLanding={setLanding}/>
                    :
                    <></>
                }
            </>
        )
    } else {
        return (<></>)
    }
}