import React, {useEffect, useState} from "react";
import {ILandingBlock} from "../types/ILandingBlock";
import {ILanding} from "../types/ILanding";
import {toNumber} from "lodash";
import SessionModel from "../stores/SessionModel";
import * as CommonActions from "./common/CommonActions";
import {EditTextBlock} from "./common/EditTextBlock";
import {sanitize} from "./common/CommonActions";
import {CONFIG} from "../CONFIG";
import {getBlockDefaults} from "./helper/Util";

export const Participants:React.FC<{
    landing: ILanding | undefined,
    setLanding: (landing: ILanding) => void}> = ({landing, setLanding}) => {

    const [block, setBlock] = useState<ILandingBlock | undefined>();
    const [showEdit, setShowEdit] = useState(false);

    useEffect(() => {
        setBlock(landing?.blocks?.filter(block => { return block.type === "PARTICIPANT" })[0]);
    }, [landing]);

    if (block) {
        return (
            <>
                <section className={block.isActive ? "newdes-section-standart" : "newdes-section-standart newdes-hidden-block"} id="people">
                    {SessionModel.getCurrentUserAuthData() && toNumber(SessionModel.getCurrentUserAuthData().id) === landing?.userId ?
                        <div className="newdes-edit-line">
                            <div className="newdes-click-edit" onClick={() => setShowEdit(true)}>
                                <div className="newdes-edit-icon"></div>
                                <p className="newdes-edit-text" onClick={() => setShowEdit(true)}>Редактировать блок</p>
                            </div>
                            {block.isActive ?
                                <p className="newdes-hidden-element" onClick={() => CommonActions.switchVisibility(block, landing, setLanding)}>Скрыть блок</p>
                                :
                                <p className="newdes-visible-element" onClick={() => CommonActions.switchVisibility(block, landing, setLanding)}>Показать</p>
                            }
                        </div>
                        :
                        <></>
                    }
                    <h2 className="newdes-heading-block">
                        {block.title || "Участники"}
                    </h2>
                    <p className="newdes-text-description" dangerouslySetInnerHTML={{__html: block.description && block.description.replace(/<\/?[^>]+(>|$)/g, "") ? sanitize(block.description || getBlockDefaults(block).description || "") : getBlockDefaults(block).description}}/>
                    <ul className="newdes-people-list">
                        <li className="newdes-people-item">
                            <img src={`${CONFIG.PUBLIC_PREFIX}/images/testimonial3.jpg`} alt="" className="newdes-people-img"/>
                        </li>
                        <li className="newdes-people-item">
                            <img src={`${CONFIG.PUBLIC_PREFIX}/images/testimonial1.jpg`} alt="" className="newdes-people-img"/>
                        </li>
                        <li className="newdes-people-item">
                            <img src={`${CONFIG.PUBLIC_PREFIX}/images/testimonial2.jpg`} alt="" className="newdes-people-img"/>
                        </li>
                    </ul>
                    {/*<ul className="newdes-tag-list">
                        <li className="newdes-tag-item">
                            от 18 лет
                        </li>
                        <li className="newdes-tag-item">
                            Студенты
                        </li>
                        <li className="newdes-tag-item">
                            Специалисты
                        </li>
                        <li className="newdes-tag-item">
                            Преподаватели
                        </li>
                    </ul>*/}
                </section>

                {showEdit ?
                    <EditTextBlock visible={showEdit} setVisible={setShowEdit} block={block} setLanding={setLanding}/>
                    :
                    <></>
                }
           </>
        )
    } else {
        return (<></>)
    }
}