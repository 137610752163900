import {CONFIG} from "../../CONFIG";
import {ILandingBlock} from "../../types/ILandingBlock";

export function getBase64(file: Blob, _callback: (b64: string | ArrayBuffer | null) => void) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        _callback(reader.result ? reader.result.toString().split(',')[1] : null);
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
}

export function b64toBlob(b64Data: string, contentType='', sliceSize= 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
}

export function formatNumber (number: string | undefined) {
    if (number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ".00";
    }
}

export function getFrontUrl() {
    console.log(CONFIG.BASE_URL);
    return CONFIG.BASE_URL.substr(0, CONFIG.BASE_URL.lastIndexOf("/")) + "/";
}

export function getBlockDefaults(block: ILandingBlock) {
    switch (block.type) {
        case "DESCRIPTION": return {title: "Описание", description: "Укажите, в чем состоит актуальность и идея конкурса, почему в нём стоит участвовать и какие возможности откроются перед участниками. Рекомендованная длина - до 700 символов"}
        case "EXPERT": return {title: "Эксперты конкурса", description: "Оценивать конкурсные работы будут опытные эксперты"}
        case "LOOT": return {title:"Призы и подарки", description: ""}
        case "PARTICIPANT": return {title:"Участники", description: "Укажите требования к участникам (возраст, география, статус, навыки, специальности и т.д.), в случае проведения командного конкурса - к командам (рекомендуемый состав команды по численности и компетенциям). \n" +
                "Например: \"К участию в конкурсе приглашаются физические лица в возрасте от 18 лет из России, увлекающиеся программированием и базами данным. Конкурс является командным, состав команды - от 1 до 4 человек\""}
        case "PARTNER": return {title: "Организатор и партнеры", description: "Расскажите участникам о компаниях, которые являются организаторами и партнёрами соревнований - как они называются, чем занимаются, в чем их привлекательность для целевой аудитории"}
        case "SCHOOL": return {title: "Онлайн-школа", description: "Посмотрите видеоуроки, чтобы подготовиться к выполнению конкурсных заданий"}
        case "TITLE": return {title: block.title, description: block.description}
        case "WTFA": return {title: "Вызов", description: "Опишите тематику соревнования и задания, которые предстоит решать участникам"}
        default: return {title: "", description: ""}
    }
}