import React from "react";
import {CONFIG} from "../CONFIG";
import {ILanding} from "../types/ILanding";

export const Navigation: React.FC<{landing: ILanding, theme: string, setTheme: (tName: string) => void}> = ({landing, theme, setTheme}) => {

    const getIsActive = (requiredType: string): boolean => {
        const block = landing?.blocks?.filter(block => {return block.type === requiredType})[0]
        if (block && block.isActive) {
            return block.isActive;
        } else {
            return false;
        }
    }

    return (
        <nav className="newdes-header-nav">
            <ul className="newdes-header-nav-list">
                <li className="newdes-header-nav-item">
                    <a href="#main" className="newdes-header-nav-link">
                        <img src={`${CONFIG.PUBLIC_PREFIX}/images/home.svg`} alt="" className="newdes-header-nav-icon"/>
                    </a>
                </li>
                {getIsActive("PARTICIPANT") ?
                    <li className="newdes-header-nav-item">
                        <a href="#people" className="newdes-header-nav-link">
                            <img src={`${CONFIG.PUBLIC_PREFIX}/images/people.svg`} alt="" className="newdes-header-nav-icon"/>
                        </a>
                    </li>
                    :
                    <></>
                }
                {getIsActive("WTFA") ?
                    <li className="newdes-header-nav-item">
                        <a href="#challenge" className="newdes-header-nav-link">
                            <img src={`${CONFIG.PUBLIC_PREFIX}/images/challenge.svg`} alt=""
                                 className="newdes-header-nav-icon"/>
                        </a>
                    </li>
                    :
                    <></>
                }
                <li className="newdes-header-nav-item">
                    <a href="#stages" className="newdes-header-nav-link">
                        <img src={`${CONFIG.PUBLIC_PREFIX}/images/stage.svg`} alt="" className="newdes-header-nav-icon"/>
                    </a>
                </li>
                {getIsActive("LOOT") ?
                    <li className="newdes-header-nav-item">
                        <a href="#prizes" className="newdes-header-nav-link">
                            <img src={`${CONFIG.PUBLIC_PREFIX}/images/prize.svg`} alt=""
                                 className="newdes-header-nav-icon"/>
                        </a>
                    </li>
                    :
                    <></>
                }
                {getIsActive("SCHOOL") ?
                    <li className="newdes-header-nav-item">
                        <a href="#video" className="newdes-header-nav-link">
                            <img src={`${CONFIG.PUBLIC_PREFIX}/images/video.svg`} alt=""
                                 className="newdes-header-nav-icon"/>
                        </a>
                    </li>
                    :
                    <></>
                }
            </ul>
            <div className="newdes-header-nav-link-theme">
                <img src={`${CONFIG.PUBLIC_PREFIX}/images/moon.svg`} id="newdes-icon-theme-white" alt=""
                     className="newdes-header-nav-icon-theme" style={{display: theme === "dark" ? "none" : ""}} onClick={() => setTheme("dark")}/>
                <img src={`${CONFIG.PUBLIC_PREFIX}/images/sun.svg`} id="newdes-icon-theme-dark" alt=""
                     className="newdes-header-nav-icon-theme-dark" style={{display: theme === "light" ? "none" : ""}} onClick={() => setTheme("light")}/>
            </div>
        </nav>
    )
}
