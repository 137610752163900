import API from "./_axios";
import {ILandingBlockRequest} from "../types/ILandingBlockRequest";
import {ILandingBlockMediaRequest} from "../types/ILandingBlockMediaRequest";

export function getLanding(challengeId: number | string, _callback: (response: any) => void) {
    API.get(`/landing/api/v1/landing/${challengeId}`).then((response) => {
        _callback(response);
    });
}

export function uploadLogo(challengeId: number, formData: FormData, type: string, _callback: (response: any) => void) {
    API.post(`/landing/api/v1/manage/${challengeId}/logo/${type}`, formData).then((response) => {
        _callback(response);
    });
}

export function deleteLogo(challengeId: number, type: string, _callback: (response: any) => void) {
    API.delete(`/landing/api/v1/manage/${challengeId}/logo/${type}`).then((response) => {
        _callback(response);
    });
}

export function switchVisibility(challengeId: number, blockId: string, _callback: (response: any) => void) {
    API.post(`/landing/api/v1/manage/${challengeId}/block/${blockId}/visibility`).then((response) => {
        _callback(response);
    });
}

export function updateBlock(landingId: string, block: ILandingBlockRequest, _callback: (response: any) => void) {
    API.put(`/landing/api/v1/manage/landing/${landingId}/block`, block).then((response) => {
        _callback(response);
    });
}

export function createBlock(landingId: string, block: ILandingBlockRequest, _callback: (response: any) => void) {
    API.post(`/landing/api/v1/manage/landing/${landingId}/block`, block).then((response) => {
        _callback(response);
    });
}

export function deleteBlock(landingId: string, blockId: string, _callback: (response: any) => void) {
    API.delete(`/landing/api/v1/manage/landing/${landingId}/block/${blockId}`).then((response) => {
        _callback(response);
    });
}

export function uploadMedia(challengeId: number, blockId: string, media: ILandingBlockMediaRequest, _callback: (response: any) => void) {
    API.post(`/landing/api/v1/manage/${challengeId}/block/${blockId}/media`, media).then((response) => {
        _callback(response);
    });
}

export function deleteMedia(challengeId: number, blockId: string, mediaId: string, _callback: (response: any) => void) {
    API.delete(`/landing/api/v1/manage/${challengeId}/block/${blockId}/media/${mediaId}`).then((response) => {
        _callback(response);
    });
}

export function getPublicResultByStageId(stageId: number, _callback: (response:any) => void) {
    API.get(`/challenge/api/v1/public/results/stage/${stageId}`).then((response) => {
        _callback(response);
    })
}