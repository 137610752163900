import React from 'react';
import {BrowserRouter as Router, useRoutes} from 'react-router-dom';
import {Main} from "./components/Main";

const App = () => {
  let routes = useRoutes([
    { path: "/:challengeId", element: <Main />}
  ]);
  return routes;
}

const AppWrapper = () => {
  return (
      <Router basename={'/landing'}>
        <App />
      </Router>
  );
};

export default AppWrapper;
