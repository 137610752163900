import React, {useEffect, useState} from "react";
import {ILanding} from "../types/ILanding";
import {ILandingBlock} from "../types/ILandingBlock";
import SessionModel from "../stores/SessionModel";
import {toNumber} from "lodash";
import * as CommonActions from "./common/CommonActions";
import {EditTextBlock} from "./common/EditTextBlock";
import {sanitize} from "./common/CommonActions";
import {CONFIG} from "../CONFIG";
import * as LandingAPI from "../requests/LandingAPI";

export const Text: React.FC<{
    landing: ILanding,
    landingBlock: ILandingBlock | undefined,
    setLanding: (landing: ILanding) => void}> = ({landing, landingBlock, setLanding}) => {

    const [block, setBlock] = useState<ILandingBlock | undefined>();
    const [showEdit, setShowEdit] = useState(false);

    useEffect(() => {
        setBlock(landingBlock);
    }, [landingBlock]);

    const deleteBlock = () => {
        if (landing.id && block?.id) {
            LandingAPI.deleteBlock(landing.id, block?.id, (response) => {
                if (response.status === 200) {
                    LandingAPI.getLanding(response.data.challengeId, (responseL) => {
                        if (responseL.status === 200) {
                            setLanding(responseL.data);
                        }
                    });
                    setLanding(response.data);
                }
            })
        }
    }

    if (block) {
        return (
            <>
                <section className={block.isActive ? "newdes-section-standart" : "newdes-section-standart newdes-hidden-block"}>
                    {SessionModel.getCurrentUserAuthData() && toNumber(SessionModel.getCurrentUserAuthData().id) === landing?.userId ?
                        <div className="newdes-edit-line">
                            <div className="newdes-click-edit" onClick={() => setShowEdit(true)}>
                                <div className="newdes-edit-icon"></div>
                                <p className="newdes-edit-text" onClick={() => setShowEdit(true)}>Редактировать блок</p>
                            </div>
                            {block.isActive ?
                                <p className="newdes-hidden-element" onClick={() => CommonActions.switchVisibility(block, landing, setLanding)}>Скрыть блок</p>
                                :
                                <p className="newdes-visible-element" onClick={() => CommonActions.switchVisibility(block, landing, setLanding)}>Показать</p>
                            }
                            <img src={`${CONFIG.PUBLIC_PREFIX}/images/delete_red.svg`} alt="" className="newdes-delete-element-line" onClick={deleteBlock}/>
                        </div>
                        :
                        <></>
                    }
                    <h2 className="newdes-heading-block">
                        {block.title || "Описание"}
                    </h2>
                    <p className="newdes-text-description" dangerouslySetInnerHTML={{__html: sanitize(block.description || "Заполните блок")}}/>
                </section>
                {showEdit ?
                    <EditTextBlock visible={showEdit} setVisible={setShowEdit} block={block} setLanding={setLanding}/>
                    :
                    <></>
                }
            </>
        )
    } else {
        return <></>
    }
}