// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.videotube-modal-overlay {
		color: #fff;
		display: none;
		z-index: 995;
		opacity: 0;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		position: fixed;
		background: #000;
		text-align: center;
}

#videotube-modal-container {
		z-index: 998;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;
}

#videotube-modal {
		text-align: center;
		box-shadow: 0 0 40px #333;
}

#videotube-modal-loading {
		position: fixed;
		z-index: 997;
		font-size: 1.5em;
		top: 49%;
		width: 5%;
		text-align: center;
		left: 45%;
}

#videotube-modal-close {
		position: fixed;
		right: 15px;
		top: 15px;
		padding: 3px 13px;
		z-index: 999;
		font-size: 25px;
		text-shadow: 0 0 10px #fff;
		border-radius: 3px;
		cursor: pointer;
		-webkit-transition-duration: 0.1s;
		-o-transition-duration: 0.1s;
		-moz-transition-duration: 0.1s;
		transition-duration: 0.1s;
}

#videotube-modal-close:hover {
		background: #333;
}
`, "",{"version":3,"sources":["webpack://./src/styles/videotube.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,YAAY;EACZ,UAAU;EACV,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,gBAAgB;EAChB,QAAQ;EACR,SAAS;EACT,kBAAkB;EAClB,SAAS;AACX;;AAEA;EACE,eAAe;EACf,WAAW;EACX,SAAS;EACT,iBAAiB;EACjB,YAAY;EACZ,eAAe;EACf,0BAA0B;EAC1B,kBAAkB;EAClB,eAAe;EACf,iCAAiC;EACjC,4BAA4B;EAC5B,8BAA8B;EAC9B,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".videotube-modal-overlay {\r\n\t\tcolor: #fff;\r\n\t\tdisplay: none;\r\n\t\tz-index: 995;\r\n\t\topacity: 0;\r\n\t\ttop: 0;\r\n\t\tleft: 0;\r\n\t\tright: 0;\r\n\t\tbottom: 0;\r\n\t\twidth: 100%;\r\n\t\tposition: fixed;\r\n\t\tbackground: #000;\r\n\t\ttext-align: center;\r\n}\r\n\r\n#videotube-modal-container {\r\n\t\tz-index: 998;\r\n\t\tposition: fixed;\r\n\t\ttop: 0;\r\n\t\tleft: 0;\r\n\t\tright: 0;\r\n\t\tbottom: 0;\r\n\t\tdisplay: flex;\r\n\t\tjustify-content: center;\r\n\t\talign-items: center;\r\n}\r\n\r\n#videotube-modal {\r\n\t\ttext-align: center;\r\n\t\tbox-shadow: 0 0 40px #333;\r\n}\r\n\r\n#videotube-modal-loading {\r\n\t\tposition: fixed;\r\n\t\tz-index: 997;\r\n\t\tfont-size: 1.5em;\r\n\t\ttop: 49%;\r\n\t\twidth: 5%;\r\n\t\ttext-align: center;\r\n\t\tleft: 45%;\r\n}\r\n\r\n#videotube-modal-close {\r\n\t\tposition: fixed;\r\n\t\tright: 15px;\r\n\t\ttop: 15px;\r\n\t\tpadding: 3px 13px;\r\n\t\tz-index: 999;\r\n\t\tfont-size: 25px;\r\n\t\ttext-shadow: 0 0 10px #fff;\r\n\t\tborder-radius: 3px;\r\n\t\tcursor: pointer;\r\n\t\t-webkit-transition-duration: 0.1s;\r\n\t\t-o-transition-duration: 0.1s;\r\n\t\t-moz-transition-duration: 0.1s;\r\n\t\ttransition-duration: 0.1s;\r\n}\r\n\r\n#videotube-modal-close:hover {\r\n\t\tbackground: #333;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
