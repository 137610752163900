import React from 'react';
import App from './App';

import './styles/newdesstyle.css';
import './styles/videotube.css';
import './styles/theme_dark.css';
import './styles/scaler.css';

var ReactDOM = require('react-dom');


const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);