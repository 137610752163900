import React from "react";
import {ILanding} from "../types/ILanding";
import {IChallengeStage} from "../types/IChallengeStage";
import {StageItem} from "./common/StageItem";

export const Stages:React.FC<{ landing: ILanding | undefined }> = ({landing}) => {

    let currentState = {state: "", protocol: ""}



    if (landing && landing.challenge && landing.challenge.stages) {
        return (
            <section className="newdes-section-standart" id="stages">
                {/*<div className="newdes-edit-line">
                    <div className="newdes-click-edit">
                        <div className="newdes-edit-icon"></div>
                        <p className="newdes-edit-text">Редактировать блок</p>
                    </div>
                    <p className="newdes-hidden-element">Скрыть блок</p>
                </div>*/}
                <h2 className="newdes-heading-block">
                    Этапы
                </h2>
                <ul className="newdes-cards-list">
                    {landing.challenge.stages?.filter(st => {return st.landingVisibility}).map((stage: IChallengeStage, index) =>
                        <StageItem stage={stage} key={index}/>
                    )}
                </ul>
            </section>
        )
    } else {
        return (<></>)
    }

}