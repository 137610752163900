// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --newdes-main-color: rgb(79, 142, 202);
  --newdes-main-color-pacity: rgba(0, 0, 0, 0.07);
  --newdes-heading-color: rgb(255, 255, 255);
  --newdes-text-color: rgb(197, 197, 197);
  --newdes-background: rgb(0, 0, 0);
  --newdes-background-edit: rgb(0, 0, 0, 0.9);
  --newdes-backfround-card: #7777a11c;
}
`, "",{"version":3,"sources":["webpack://./src/styles/theme_dark.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;EACtC,+CAA+C;EAC/C,0CAA0C;EAC1C,uCAAuC;EACvC,iCAAiC;EACjC,2CAA2C;EAC3C,mCAAmC;AACrC","sourcesContent":[":root {\n  --newdes-main-color: rgb(79, 142, 202);\n  --newdes-main-color-pacity: rgba(0, 0, 0, 0.07);\n  --newdes-heading-color: rgb(255, 255, 255);\n  --newdes-text-color: rgb(197, 197, 197);\n  --newdes-background: rgb(0, 0, 0);\n  --newdes-background-edit: rgb(0, 0, 0, 0.9);\n  --newdes-backfround-card: #7777a11c;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
