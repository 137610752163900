import * as LandingAPI from "../../requests/LandingAPI";
import {toNumber} from "lodash";
import {ILandingBlock} from "../../types/ILandingBlock";
import {ILanding} from "../../types/ILanding";
import * as sanitizeHtml from 'sanitize-html';

export function switchVisibility(block: ILandingBlock, landing: ILanding, setLanding: (landing: ILanding) => void) {
    if (block?.id) {
        LandingAPI.switchVisibility(toNumber(landing?.challengeId), block?.id, (response) => {
            if (response.status === 200) {
                setLanding(response.data);
            }
        })
    }
}

export function sanitize(text: string) {
    // @ts-ignore
    let clear = sanitizeHtml(text, {
        allowedTags: ['b', 'i', 'em', 'strong', 'a', 'ol', 'li', 'ul', 'u', 'p'],
        allowedAttributes: {
            'a': ['href']
        },
        allowedIframeHostnames: []
    });
    return clear.replaceAll("<a", "<a class=\"newdes-link-text\" target=\"_blank\" ");
}