import React, {useEffect, useState} from "react";
import {ILandingBlock} from "../../types/ILandingBlock";
import {ILanding} from "../../types/ILanding";
import {ILandingBlockRequest} from "../../types/ILandingBlockRequest";
import * as LandingAPI from "../../requests/LandingAPI";

export const EditSchoolBlock: React.FC<{
    block: ILandingBlock,
    subBlock: ILandingBlock | undefined,
    visible: boolean,
    setVisible: (visibility: boolean, block: ILandingBlock | undefined) => void,
    setLanding: (landing: ILanding) => void}> = ({visible, setVisible, block, subBlock, setLanding}) => {

    const [currentBlock, setCurrentBlock] = useState<ILandingBlockRequest>();
    const [linkError, setLinkError] = useState(false);

    useEffect(() => {
        if (subBlock) {
            setCurrentBlock({...currentBlock,
                id: subBlock.id,
                title: subBlock.title,
                description: subBlock.description,
                landingId: block.landingId,
                parentId: block.id,
                remoteMedia: subBlock.mediaList ? subBlock.mediaList[0].remoteMedia : undefined,
                availabilityDate: subBlock.mediaList && subBlock.mediaList[0].availabilityDate ? subBlock.mediaList[0].availabilityDate.substring(0, 10) : undefined})
        } else {
            setCurrentBlock({...currentBlock, title: "", description: "", landingId: block.landingId, parentId: block.id})
        }
    }, [block]);

    const save = () => {
        if (!currentBlock?.remoteMedia) {
            setLinkError(true);
            return;
        }
        if (block.landingId && currentBlock && !currentBlock.id) {
            LandingAPI.createBlock(block.landingId, currentBlock, (response) => {
                if (response.status === 200) {
                    LandingAPI.getLanding(response.data.challengeId, (responseL) => {
                        if (responseL.status === 200) {
                            setLanding(responseL.data);
                        }
                    });
                    setVisible(false, undefined);
                }
            })
        } else if (block.landingId && currentBlock && currentBlock.id) {
            LandingAPI.updateBlock(block.landingId, currentBlock, (response) => {
                if (response.status === 200) {
                    setLanding(response.data);
                    setVisible(false, undefined);
                }
            })
        }
    }

    if (visible) {
        return (
            <section className="newdes-edit-block">
                <h4 className="newdes-heading-edit">
                    Название видео
                </h4>
                <form action="" className="newdes-edit-form">
                    <fieldset className="newdes-fieldset">
                        <legend className="newdes-legend">Заголовок</legend>
                        <input type="text" className="newdes-edit-input" placeholder="Введите заголовок" value={currentBlock?.title} onChange={(e => setCurrentBlock({...currentBlock, title: e.target.value}))} size={55}/>
                    </fieldset>
                    <fieldset className="newdes-fieldset">
                        <legend className="newdes-legend" style={{color: linkError? "red" : ""}}>Ссылка на видео</legend>
                        <input type="text" className="newdes-edit-input" value={currentBlock?.remoteMedia} onChange={(e => setCurrentBlock({...currentBlock, remoteMedia: e.target.value}))} placeholder="Ссылка на youtube"/>
                    </fieldset>
                    <fieldset className="newdes-fieldset">
                        <legend className="newdes-legend">Укажите, с какой даты будет доступно данное видео (оставьте пустым, если не требуется)</legend>
                        <input type="date" value={currentBlock?.availabilityDate} onChange={(e => setCurrentBlock({...currentBlock, availabilityDate: e.target.value}))} className="newdes-edit-input" placeholder="Дата доступности"/>
                    </fieldset>

                </form>
                <div className="newdes-edit-buttons">
                    <a className="newdes-btn edit-done" onClick={save}>Сохранить</a>
                    <a className="newdes-btn edit-cancel" onClick={() => setVisible(false, undefined)}>Отмена</a>
                </div>
            </section>
        );
    } else {
        return (<></>)
    }
}