import React, {useEffect, useState} from "react";
import {ILanding} from "../../types/ILanding";
import {ILandingBlockRequest} from "../../types/ILandingBlockRequest";
import {ILandingBlock} from "../../types/ILandingBlock";
import * as LandingAPI from "../../requests/LandingAPI";
import {CONFIG} from "../../CONFIG";

export const EditLootBlock: React.FC<{
    block: ILandingBlock,
    subBlock: ILandingBlock | undefined,
    visible: boolean,
    setVisible: (visibility: boolean, block: ILandingBlock | undefined) => void,
    setLanding: (landing: ILanding) => void}> = ({visible, setVisible, block, subBlock, setLanding}) => {

    const [currentBlock, setCurrentBlock] = useState<ILandingBlockRequest>();
    const [noImageError, setNoImageError] = useState(false);

    useEffect(() => {
        if (subBlock) {
            setCurrentBlock({...currentBlock,
                id: subBlock.id,
                title: subBlock.title,
                description: subBlock.description,
                landingId: block.landingId,
                parentId: block.id,
                remoteMedia: subBlock.mediaList ? subBlock.mediaList[0].remoteMedia : undefined})
        } else {
            setCurrentBlock({...currentBlock, title: "", description: "", landingId: block.landingId, parentId: block.id})
        }
    }, [block]);

    const save = () => {
        setNoImageError(false);
        if (!currentBlock?.remoteMedia) {
            setNoImageError(true);
            return;
        }
        if (block.landingId && currentBlock && !currentBlock.id) {
            LandingAPI.createBlock(block.landingId, currentBlock, (response) => {
                if (response.status === 200) {
                    LandingAPI.getLanding(response.data.challengeId, (responseL) => {
                        if (responseL.status === 200) {
                            setLanding(responseL.data);
                        }
                    });
                    setVisible(false, undefined);
                }
            })
        } else if (block.landingId && currentBlock && currentBlock.id) {
            LandingAPI.updateBlock(block.landingId, currentBlock, (response) => {
                if (response.status === 200) {
                    setLanding(response.data);
                    setVisible(false, undefined);
                }
            })
        }
    }

    const pickImage = (e: React.MouseEvent<HTMLImageElement>) => {
        let splinted = e.currentTarget.src.split("/");
        setCurrentBlock({...currentBlock, remoteMedia: splinted[splinted.length - 1]})
    }

    if (visible) {
        return (
            <section className="newdes-edit-block">
                <h4 className="newdes-heading-edit">
                    Название блока
                </h4>
                <form action="" className="newdes-edit-form">
                    <fieldset className="newdes-fieldset">
                        <legend className="newdes-legend">Заголовок</legend>
                        <input type="text" className="newdes-edit-input" placeholder="Введите заголовок" value={currentBlock?.title} onChange={(e => setCurrentBlock({...currentBlock, title: e.target.value}))}/>
                    </fieldset>
                    <textarea cols={55} rows={10} className="newdes-edit-input newdes-textarea" placeholder="Введите текст" value={currentBlock?.description} onChange={(e => setCurrentBlock({...currentBlock, description: e.target.value}))}></textarea>
                    <div className="newdes-form-prize-load">
                        <p className="newdes-legend" style={{color: noImageError ? "red" : ""}}>Выберите изображение</p>
                        <ul className="newdes-form-flex-prize">
                            <li className={`newdes-form-prize ${currentBlock?.remoteMedia === "money_small.png" ? "newdes-prize-active" : ""}`}>
                                {currentBlock?.remoteMedia === "money_small.png" ?
                                    <img src={`${CONFIG.PUBLIC_PREFIX}/images/active_element.svg`} alt="" className="newdes-active-prize-img"/>
                                    :
                                    <></>
                                }
                                <img src={`${CONFIG.PUBLIC_PREFIX}/images/money_small.png`} alt="" className="newdes-form-prize-img" onClick={pickImage}/>
                            </li>
                            <li className={`newdes-form-prize ${currentBlock?.remoteMedia === "kubok_small.png" ? "newdes-prize-active" : ""}`}>
                                {currentBlock?.remoteMedia === "kubok_small.png" ?
                                    <img src={`${CONFIG.PUBLIC_PREFIX}/images/active_element.svg`} alt="" className="newdes-active-prize-img"/>
                                    :
                                    <></>
                                }
                                <img src={`${CONFIG.PUBLIC_PREFIX}/images/kubok_small.png`} alt="" className="newdes-form-prize-img" onClick={pickImage}/>
                            </li>
                            <li className={`newdes-form-prize ${currentBlock?.remoteMedia === "kruzhka_small.png" ? "newdes-prize-active" : ""}`}>
                                {currentBlock?.remoteMedia === "kruzhka_small.png" ?
                                    <img src={`${CONFIG.PUBLIC_PREFIX}/images/active_element.svg`} alt="" className="newdes-active-prize-img"/>
                                    :
                                    <></>
                                }
                                <img src={`${CONFIG.PUBLIC_PREFIX}/images/kruzhka_small.png`} alt="" className="newdes-form-prize-img" onClick={pickImage}/>
                            </li>
                            <li className={`newdes-form-prize ${currentBlock?.remoteMedia === "sert_small.png" ? "newdes-prize-active" : ""}`}>
                                {currentBlock?.remoteMedia === "sert_small.png" ?
                                    <img src={`${CONFIG.PUBLIC_PREFIX}/images/active_element.svg`} alt="" className="newdes-active-prize-img"/>
                                    :
                                    <></>
                                }
                                <img src={`${CONFIG.PUBLIC_PREFIX}/images/sert_small.png`} alt="" className="newdes-form-prize-img" onClick={pickImage}/>
                            </li>
                            <li className={`newdes-form-prize ${currentBlock?.remoteMedia === "edu_small.png" ? "newdes-prize-active" : ""}`}>
                                {currentBlock?.remoteMedia === "edu_small.png" ?
                                    <img src={`${CONFIG.PUBLIC_PREFIX}/images/active_element.svg`} alt="" className="newdes-active-prize-img"/>
                                    :
                                    <></>
                                }
                                <img src={`${CONFIG.PUBLIC_PREFIX}/images/edu_small.png`} alt="" className="newdes-form-prize-img" onClick={pickImage}/>
                            </li>
                        </ul>
                    </div>
                </form>
                <div className="newdes-edit-buttons">
                    <a className="newdes-btn edit-done" onClick={save}>Сохранить</a>
                    <a className="newdes-btn edit-cancel" onClick={() => setVisible(false, undefined)}>Отмена</a>
                </div>
            </section>
        )
    } else {
        return (<></>);
    }
}