import * as process from "process";

let BASE_URL;
let STORAGE_URL;
let PUBLIC_PREFIX;
const PLATFORM_ADMIN_USER_TG = "https://t.me/Braim_org";
const PLATFORM_ADMIN_ORG_TG = "https://t.me/Braim_org";
const HOWTO_USER_LINK = "https://braim.org/howto-participants.html";
const HOWTO_ORG_LINK = "https://braim.org/howto-org.html";
const TARGET_ENV = process.env.TARGET_ENV;

if(TARGET_ENV === "production") {
    BASE_URL = "/api";
    STORAGE_URL = "/storage";
    PUBLIC_PREFIX = "/landing";
} else if(TARGET_ENV === "development") {
    BASE_URL = "https://dev.braim.org/api";
    STORAGE_URL = "https://dev.braim.org/storage";
    PUBLIC_PREFIX = "/landing";
} else {
    BASE_URL = "http://localhost:8080/";
    STORAGE_URL = "http://localhost:9090/storage";
    PUBLIC_PREFIX = "";
}


export const CONFIG = {
    BASE_URL: BASE_URL,
    STORAGE_URL: STORAGE_URL,
    PUBLIC_PREFIX: PUBLIC_PREFIX,
    PLATFORM_ADMIN_ORG_TG: PLATFORM_ADMIN_ORG_TG,
    PLATFORM_ADMIN_USER_TG: PLATFORM_ADMIN_USER_TG,
    HOWTO_ORG_LINK: HOWTO_ORG_LINK,
    HOWTO_USER_LINK: HOWTO_USER_LINK,
    TARGET_ENV: TARGET_ENV
}