import React, {useEffect, useState} from "react";
import {ILandingBlock} from "../../types/ILandingBlock";
import {ILandingBlockRequest} from "../../types/ILandingBlockRequest";
import * as LandingAPI from "../../requests/LandingAPI";
import {ILanding} from "../../types/ILanding";
import '../../styles/quill-custom.css';
import ReactQuill from "react-quill";
import {getBlockDefaults} from "../helper/Util";

export const EditTextBlock: React.FC<{
    block: ILandingBlock,
    visible: boolean,
    setVisible: (visibility: boolean) => void,
    setLanding: (landing: ILanding) => void}> = ({visible, setVisible, block, setLanding}) => {

    const [currentBlock, setCurrentBlock] = useState<ILandingBlockRequest>();
    const [description, setDescription] = useState(block.description || "");

    useEffect(() => {
        setCurrentBlock(block);
    }, [block]);

    useEffect(() => {
        if (currentBlock && currentBlock.id) {
            setTimeout(() => {
                setCurrentBlock({...currentBlock, description: description});
            }, 100);
        }
    }, [description])

    const save = () => {
        if (block.landingId && currentBlock) {
            LandingAPI.updateBlock(block.landingId, currentBlock, (response) => {
                if (response.status === 200) {
                    setLanding(response.data);
                    setVisible(false);
                }
            })
        }
    }

    if (visible) {
        return (
            <section className="newdes-edit-block">
                <h4 className="newdes-heading-edit">
                    Название блока
                </h4>
                <form action="" className="newdes-edit-form">
                    <fieldset className="newdes-fieldset">
                        <legend className="newdes-legend">Заголовок</legend>
                        <input type="text" className="newdes-edit-input" placeholder="Введите заголовок" value={currentBlock?.title || getBlockDefaults(block).title} onChange={(e => setCurrentBlock({...currentBlock, title: e.target.value}))}/>
                    </fieldset>
                    {block.type === "LOOT" ? <></> : <ReactQuill theme={"snow"} value={description} onChange={setDescription} placeholder={getBlockDefaults(block).description}/>}

                    {/*<textarea cols={55} rows={10} className="newdes-edit-input newdes-textarea" placeholder="Введите текст" value={currentBlock?.description} onChange={(e => setCurrentBlock({...currentBlock, description: e.target.value}))}></textarea>*/}
                </form>
                <div className="newdes-edit-buttons">
                    <a className="newdes-btn edit-done" onClick={save}>Сохранить</a>
                    <a className="newdes-btn edit-cancel" onClick={() => setVisible(false)}>Отмена</a>
                </div>
            </section>
        )
    } else {
        return (<></>);
    }
}