import React, {useEffect, useState} from "react";
import {IChallengeStage} from "../../types/IChallengeStage";
import {sanitize} from "./CommonActions";
import {getFrontUrl} from "../helper/Util";
import {formatDate} from "../helper/DateHelper";
import * as LandingAPI from "../../requests/LandingAPI";

export const StageItem:React.FC<{stage: IChallengeStage}> = ({stage}) => {

    const [currentStage, setCurrentStage] = useState<IChallengeStage>(stage);

    useEffect(() => {
        if (currentStage.startDate && currentStage.endDate && currentStage.id) {
            LandingAPI.getPublicResultByStageId(currentStage.id, (response) => {
                if (response.status === 200) {
                    setCurrentStage({...currentStage, protocol: `challenge/${currentStage.challengeId}/stage/${currentStage.id}/protocol/${response.data.id}`});
                }
            })

        }
    }, [stage]);

    const getStatus = (stage: IChallengeStage): string => {
        let status = "";
        if (stage.startDate && stage.endDate && stage.id) {
            let startDate = stage.startDate.substring(0, 19) + "+03:00";
            let endDate = stage.endDate.substring(0,19) + "+03:00";
            /*if (currentStage.type === "VIDEO" || currentStage.type === "INFO") {
                return "Активный этап";
            }*/
            console.log(currentStage.name);
            console.log(currentStage.startDate);
            console.log(currentStage.endDate);
            if (new Date(startDate) <= new Date() && new Date(endDate) >= new Date()) {
                status = "Активный этап";
            } else if (new Date(endDate) < new Date()) {
                status = "Прошедший этап";
            } else if (new Date(startDate) > new Date()) {
                status = "Будущий этап";
            }
        }
        return status;
    }


    return (
        <li className="newdes-cards-item-stage">
            <h3 className={getStatus(stage) === "Активный этап" ? "newdes-stage-card-subline newdes-active" : "newdes-stage-card-subline newdes"}>
                {getStatus(stage)}
            </h3>
            <h2 className="newdes-stage-card-headline">
                {stage.name}
            </h2>
            <p className="newdes-stage-card-description" dangerouslySetInnerHTML={{__html: sanitize(stage.description || "")}}/>
            <div className="newdes-stage-flex-wrapper">
                {getStatus(stage) === "Активный этап" && !currentStage.protocol ?
                    <a href={`${getFrontUrl()}challenge/${currentStage?.challengeId}/my`} target="_blank"
                       className="newdes-btn">Перейти к заданию</a>
                    :
                    currentStage.protocol ?
                        <a href={`${getFrontUrl()}${currentStage.protocol}`} target="_blank"
                           className="newdes-btn">Протокол этапа</a>
                        :
                        <a target="_blank" className="newdes-btn" style={{cursor: "default"}}>Задание недоступно</a>
                }
                <p className="newdes-stage-card-date">
                    {formatDate(stage.startDate)} - {formatDate(stage.endDate)}
                </p>
            </div>
        </li>
    )
}